import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import Head from '../components/head';

const PostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`;

const PostCard = styled.div`
  margin: 20px;
  width: 320px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
  border-radius: 5px;
  padding: 30px 30px;
`;

const PostImg = styled.img`
  margin: 10px 0;
`;

const ReadBtn = styled(Link)`
  color: #fff;
  padding: 8px;
  display: block;
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px;
  background: rgb(49, 85, 220, 0.9);
  :hover {
    background: rgb(49, 85, 220);
  }
`;

const GreyText = styled.h3`
  color: #999;
  letter-spacing: 1.3px;
  line-height: 1.5;
`;

export default function BlogPage({ data }) {
  return (
    <Layout>
      <Head title="Blog" />
      <PostContainer>
        {data.allMdx.edges.length < 1 ? (
          <GreyText>Oops! Nothing to show.</GreyText>
        ) : null}
        {data.allMdx.edges.map(({ node }) => (
          <PostCard key={node.id}>
            <h2>{node.frontmatter.title}</h2>
            <PostImg src={node.frontmatter.bannerUrl} />
            <p>{node.frontmatter.description}</p>
            <ReadBtn to={`${node.fields.slug}`}>Read</ReadBtn>
          </PostCard>
        ))}
      </PostContainer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            description
            bannerUrl
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
